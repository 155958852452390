import {Toast} from 'vant'
import 'vant/es/toast/style';
export const copy = <T extends string>(str: T) => {
    let domInput = document.createElement('input');
    domInput.value = str;
    document.body.appendChild(domInput);
    domInput.select();
    document.execCommand("Copy");
    domInput.remove()
    Toast('复制成功')
    return true
  }
  
  export const downOpen= <T extends string>(url: T) => {
    window.open(url)
  }

import http from '../utils/request'

export const website = () => {
    return http.request({
        url: '/learn/website',
        method: 'get'
    })
}

export const tool = (type: number) => {
    return http.request({
        url: '/learn/downtool',
        method: 'get',
        params: {
            type
        }
    })
}

export const resume = () => {
    return http.request({
        url: '/learn/resume',
        method: 'get'
    })
}

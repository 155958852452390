import { copy, downOpen } from '@/utils/copyText'
import { encrypt } from '@/utils/myhash'

import store from '@/store'
import { resumeInit, toolInit } from '@/types/learn_meun';
import { Toast } from 'vant';
import router from '@/router';
export const TipsHandal = (type: number, tool: toolInit | resumeInit<boolean>, fun: ()=> void) => {
    const info = store.state.userInfo
    if  (tool.vip && !info.id) {
        Toast('请先登录！')
        router.push({name: 'login'})
        return 
    }
    if  (tool.vip && !info.is_vip) return fun()
    if(type === 0) { //下载
        downOpen(encrypt(tool.link)!)
    } else if(type === 1) { //复制链接
        copy(encrypt(tool.link)!)
    }
}
import http from '../utils/request'

interface feedbackInit {
    user_id: number
    device?: string
}
export default interface VipUser<T> {
    user_id : any
    pid: String | T
    sign: String | T
    sign_type: String | T
    money: String | T
    name: String | T
    param: String | T
    type: String | T
    out_trade_no: String | T
    trade_no: String | T
    trade_status: String | T
  }
  
export const paymentVip = (data: feedbackInit) => {
    return http.request({
        url: '/pay/vip/payment',
        method: 'POST',
        data
    })
}

export const payVip = (data: VipUser<any>) => {
    return http.request({
        url: '/pay/vip/payment/record',
        method: 'POST',
        data
    })
}

export interface barType<T> {
    title: T
    name: T
    tag: number
}
export class barInit {
    barList: barType<string>[] = [
        {
            title: '站点推荐',
            name: 'website',
            tag: 1
        },
        {
            title: '工具下载',
            name: 'tooldow',
            tag: 2
        },
        {
            title: '大厂面试题',
            name: 'handbook',
            tag: 3
        },
        {
            title: '简历模板',
            name: 'resume',
            tag: 4
        }
    ]
}

// learn 模块
interface webInit {
    res_website: res_web_list
    tag_name: string
    tid: number
}
interface res_web_list {
    createAt: string
    desc: string
    id: number
    image: string
    link: string
    title: string
    updateAt: string
    websit_id: number
}
export class websiteList {
    list: webInit[] = []
}

export interface toolInit {
    desc: string
    id: number
    image: string
    link: string
    title: string
    type: number
    down_count: number
    vip: boolean
}
export class listTool {
    list: toolInit[] = []
}

export interface resumeInit<T> {
    id: number
    image: string
    link: string
    vip: T
    down_count: number
}
export class ResumeLIist {
    list: resumeInit<boolean>[] = []
}